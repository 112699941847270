.services-page {
  padding: 40px;
  text-align: center;
}

.services-page h2 {
  font-size: 2.5rem;
  color: #333;
}

.services-page p {
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 30px;
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Always 2 cards per row */
  gap: 30px; /* Space between cards */
  justify-content: center; /* Center-align the grid content */
}

.service-card {
  max-width: 100%; /* Cards take full width of the column */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  background: #fff;
}

.service-card img {
  width: 100%;
  height: auto;
}

.service-card h3 {
  font-size: 1.5rem;
  margin: 15px;
}

.service-card p {
  font-size: 1rem;
  margin: 15px;
  color: #555;
}

.service-card button {
  display: block;
  margin: 15px auto 20px;
  padding: 10px 20px;
  background: #ff6600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.service-card button:hover {
  background: #e65500;
}
