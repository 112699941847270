#contact {
    padding: 2rem;
    background-color: #ffffff;
    color: #fff;
    text-align: center;
  }
  
  #contact h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #ff5722;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 400px;
    margin: 0 auto;
  }
  
  form input, form textarea, form button {
    padding: 0.8rem;
    border-radius: 5px;
    border: none;
  }
  
  form input, form textarea {
    background-color: #ffffff;
    color: #000000;
  }
  
  form button {
    background-color: #ff5722;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  form button:hover {
    background-color: #e64a19;
  }
  