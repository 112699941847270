.contact-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px;
  gap: 20px; /* Add gap for consistent spacing */
}

.contact-form {
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.contact-info {
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  background-color: #f9f9f9;
}

.contact-form h2,
.contact-info h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group input[type="tel"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.contact-info p {
  margin-bottom: 10px;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .contact-section {
    flex-direction: column; /* Stack form and info vertically */
    align-items: center;
    padding: 30px; /* Adjust padding for medium screens */
  }

  .contact-form,
  .contact-info {
    width: 90%; /* Reduce width for better fit */
    margin-bottom: 20px; /* Add space between stacked sections */
  }

  .contact-form h2,
  .contact-info h3 {
    font-size: 22px; /* Adjust heading font size */
  }
}

@media screen and (max-width: 768px) {
  .contact-section {
    padding: 20px; /* Further reduce padding for smaller screens */
  }

  .form-group input,
  .form-group textarea {
    font-size: 14px; /* Adjust input text size for smaller screens */
  }

  button[type="submit"] {
    padding: 8px 16px; /* Reduce button size */
    font-size: 14px; /* Adjust button font size */
  }
}

@media screen and (max-width: 480px) {
  .contact-section {
    padding: 15px; /* Compact padding for mobile screens */
  }

  .contact-form,
  .contact-info {
    width: 100%; /* Take full width on mobile */
    padding: 15px; /* Compact padding for sections */
  }

  .contact-form h2,
  .contact-info h3 {
    font-size: 20px; /* Reduce heading font size */
  }

  button[type="submit"] {
    padding: 6px 12px; /* Compact button size */
    font-size: 12px; /* Compact button font size */
  }
}
