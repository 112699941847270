/* General Page Styling */
.sustainability-page {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;  /* Default text color */
  padding: 20px;
}

/* Hero Section */
.hero-section {
  background-image: url('../images/ev.png'); /* Hero Image */
  background-size: cover;
  background-position: center;
  height: 400px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-section h1 {
  font-size: 3em;
  margin: 0;
}

.hero-section p {
  font-size: 1.2em;
  margin-top: 10px;
}

/* Roadmap Section */
.roadmap-section {
  margin-top: 40px;
  text-align: center;
}

.roadmap h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.roadmap {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* Individual Roadmap Items - Increased size */
.roadmap-item {
  background: linear-gradient(145deg, #ff9a8b, #94ff6a); /* Colorful Gradient */
  border-radius: 8px;
  padding: 30px; /* Increased padding */
  width: 350px;  /* Increased card width */
  margin: 20px;  /* Increased margin */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);  /* Slightly stronger shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

/* Hover Effect */
.roadmap-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 18px rgba(0, 0, 0, 0.2);  /* Deepened shadow on hover */
}

/* Icon Styling */
.roadmap-item i {
  font-size: 4em;  /* Increased icon size */
  margin-bottom: 20px;  /* Increased space between icon and heading */
  color: #fff;
}

/* Heading Styling */
.roadmap-item h3 {
  font-size: 1.8em;  /* Increased font size */
  margin-bottom: 15px;  /* Increased spacing below the heading */
  color: white;
}

/* Paragraph Styling */
.roadmap-item p {
  font-size: 1.2em;  /* Increased paragraph font size */
  color: white;
}

/* Collaboration Section */
.collaboration-section {
  margin-top: 50px;
  text-align: center;
}

.collaboration-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: black; /* Black color for the heading */
}

.collaboration-section p {
  font-size: 1.2em;
  color: black; /* Black color for the paragraph */
  max-width: 700px;
  margin: 0 auto;
}

/* Footer Styling */
.sustainability-footer {
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 40px;
  margin-top: 100px;
  border-radius: 8px;
  font-size: 2rem; /* Adjust font size as needed */
  font-weight: bold;
  line-height: 1.2;
}

.sustainability-footer p {
  color: black; /* Set text color in footer to black */
}
