header {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  color: #000000;
}

header .logo img {
  height: 75px; /* Retaining the current logo height */
}

nav ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

nav ul li a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.quote-button {
  background-color: #ff5722;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.quote-button:hover {
  background-color: #e64a19;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  header {
    flex-wrap: wrap; /* Allow header elements to wrap if necessary */
    padding: 1rem; /* Reduce padding for smaller screens */
  }

  nav ul {
    flex-direction: column; /* Stack navigation links vertically */
    gap: 1rem; /* Adjust spacing between links */
    align-items: center; /* Center align links */
  }

  nav ul li a {
    font-size: 1rem; /* Slightly smaller font size for mobile */
  }

  .quote-button {
    padding: 0.4rem 0.8rem; /* Adjust button padding for smaller screens */
    font-size: 0.9rem; /* Scale down font size */
  }
}

@media screen and (max-width: 480px) {
  header {
    flex-direction: column; /* Stack logo and navigation vertically */
    text-align: center; /* Center align all content */
  }

  header .logo img {
    height: 60px; /* Smaller logo for very small screens */
  }

  nav ul {
    flex-direction: column; /* Keep links stacked vertically */
    gap: 0.8rem; /* Reduce spacing further */
  }

  nav ul li a {
    font-size: 0.9rem; /* Smaller font size for very small screens */
  }

  .quote-button {
    padding: 0.3rem 0.7rem; /* Compact button size */
    font-size: 0.8rem; /* Compact font size for very small screens */
  }
}
