.image-section {
  display: flex;
  gap: 40px; /* Space between image and text sections */
  padding: 20px;
  align-items: flex-start;
}

.image-container {
  flex: 1;
  max-width: 300px; /* Limit width to control image size */
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: add rounded corners */
}

.text-container {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 40px; /* Space between columns */
}

.text-container ul {
  list-style-type: none; /* Remove default bullets */
  padding: 0;
  margin: 0;
  flex: 1;
  min-width: 200px; /* Minimum width for each column */
}

.text-container li {
  position: relative;
  padding-left: 25px; /* Space for tick symbol */
  margin-bottom: 10px;
}

.text-container li::before {
  content: '✔'; /* Unicode for tick symbol */
  position: absolute;
  left: 0;
  color: #28a745; /* Color for tick */
  font-weight: bold;
}
