#metrics {
  display: flex;
  justify-content: space-around;
  background-color: #f5f5f5;
  padding: 2rem;
  text-align: center;
}

.metric {
  flex: 1;
  color: #333;
  font-family: Arial, sans-serif;
}

.metric h2 {
  font-size: 2.5rem;
  color: #ff5722;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.metric p {
  font-size: 1.1rem;
  color: #666;
}

.metric-image {
  width: 80px; /* Adjust the size of the image */
  height: auto;
  margin-bottom: 1rem; /* Space between the image and the text */
}
