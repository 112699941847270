.technology-section {
  text-align: center;
  padding: 20px;
}

.technology-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default 4 columns for large screens */
  gap: 20px;
  justify-content: center;
}

.technology-item {
  max-width: 200px; /* Limit the width of each item */
  margin: 0 auto; /* Center each item */
  text-align: center;
}

.technology-item img {
  max-width: 100px; /* Ensure images are responsive */
  height: auto;
}

.technology-item p {
  font-size: 16px;
  margin-top: 10px;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .technology-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
  }
}

@media screen and (max-width: 768px) {
  .technology-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    gap: 15px; /* Reduce spacing between items */
  }

  .technology-item img {
    max-width: 80px; /* Reduce image size for smaller screens */
  }

  .technology-item p {
    font-size: 14px; /* Adjust text size for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .technology-grid {
    grid-template-columns: 1fr; /* 1 column for small screens */
    gap: 10px; /* Further reduce spacing */
  }

  .technology-item img {
    max-width: 60px; /* Further reduce image size for small screens */
  }

  .technology-item p {
    font-size: 12px; /* Compact text for mobile screens */
  }
}
