#highlights {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem;
  background-color: #ffffff;
  color: #fff;
  text-align: center;
}

.highlight {
  background-color: #ffffff;
  flex: 1 1 calc(50% - 1rem); /* Adjusting layout to 2 columns */
  padding: 1.5rem;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.highlight h3 {
  color: #ff5722;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.2rem;
}

.highlight p {
  color: #000000;
  
  font-size: 1rem;
}

.highlight:hover {
  transform: translateY(-5px);
}
