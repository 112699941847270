.karunadu-container {
  text-align: center;
  padding: 20px;
}
.karunadu-container h2 {
  font-size: 2.5rem; /* Increased font size for the main heading */
}

.karunadu-container p {
  font-size: 1.4rem; /* Increased font size for the paragraph text */
  line-height: 1.6; /* Optional: Adjust line height for better readability */
}


.feature-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.feature-card {
  flex-basis: 30%; /* Adjust width as needed */
  margin: 10px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.feature-card img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px; /* Optional: Add margin to separate image from text */
}

.feature-card h3 {
  font-size: 2rem; /* Increased font size for the feature title */
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 1.2rem; /* Increased font size for descriptions */
}

.feature-content {
  text-align: center;
}
