.fleet-section {
  padding: 40px;
  background-color: #f5f5f5;
  text-align: left; /* Left-align the content */
}

.tabs {
  display: flex;
  justify-content: center; /* Center-align tabs */
  flex-wrap: wrap; /* Allow tabs to wrap on smaller screens */
  margin-bottom: 20px;
  gap: 10px; /* Add spacing between tabs */
}

.tab-btn {
  background-color: #e35e10;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border-radius: 5px; /* Add rounded corners */
}

.tab-btn:hover {
  background-color: #444546;
}

.tab-btn.active {
  background-color: #0056b3;
}

.fleet-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align fleet container items to the left */
  gap: 20px; /* Add spacing between vehicle cards */
}

.fleet-category {
  display: none; /* Initially hide all categories */
  flex-wrap: wrap;
  justify-content: flex-start; /* Left-align content in each category */
  width: 100%;
  gap: 20px; /* Add spacing between items */
}

.fleet-category.active {
  display: flex; /* Display active categories */
}

.vehicle-card {
  background-color: white;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 250px; /* Set uniform width for all cards */
  height: 350px; /* Set a fixed height for consistency */
  padding: 20px;
  text-align: left; /* Left-align content inside the vehicle card */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vehicle-card img {
  width: 100%; /* Ensure images fit the card width */
  height: 200px; /* Set uniform height for images */
  object-fit: cover; /* Crop and scale images uniformly */
  border-radius: 8px;
}

.vehicle-info h3 {
  font-size: 18px;
  margin-top: 15px;
}

.vehicle-info p {
  font-size: 14px;
  color: #555;
}

.contact-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block; /* Ensure button is inline */
}

.contact-btn:hover {
  background-color: #218838;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .tabs {
    gap: 5px; /* Adjust spacing between tabs for medium screens */
  }

  .fleet-container {
    justify-content: center; /* Center items on medium screens */
  }

  .vehicle-card {
    width: 300px; /* Adjust card width for better fit */
  }
}

@media screen and (max-width: 768px) {
  .fleet-section {
    padding: 30px; /* Adjust padding for smaller screens */
  }

  .tab-btn {
    padding: 8px 16px; /* Reduce button size */
    font-size: 14px; /* Adjust font size */
  }

  .fleet-container {
    justify-content: center; /* Center items on small screens */
  }

  .vehicle-card {
    width: 90%; /* Allow cards to take more space */
    margin: 0 auto 20px; /* Center-align and add spacing */
  }
}

@media screen and (max-width: 480px) {
  .fleet-section {
    padding: 20px; /* Compact padding for mobile screens */
  }

  .tab-btn {
    padding: 6px 12px; /* Compact tab size */
    font-size: 12px; /* Reduce font size */
  }

  .vehicle-card {
    width: 100%; /* Full width for mobile screens */
    margin-bottom: 20px; /* Add spacing between cards */
  }

  .contact-btn {
    padding: 8px 16px; /* Compact button size */
    font-size: 12px; /* Compact button font size */
  }
}
