/* Reset body margin */
body {
  margin: 0;
  padding: 0;
}

/* Footer styling */
footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 0; /* Remove any extra padding */
  margin: 0; /* Remove any margin */
  width: 100%; /* Ensure full width */
}

/* Footer line container */
.footer-line {
  margin: 0; /* Ensure the container spans edge-to-edge */
  padding: 0;
  overflow: hidden; /* Ensure image covers container without overflow */
  width: 100%;
}

/* Image inside footer */
.footer-line img {
  width: 100vw; /* Make image span the full viewport width */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove extra white space around the image */
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any padding */
  object-fit: cover; /* Ensures the image fully covers the container */
}



.footer-links a:hover {
  text-decoration: underline;
}

footer p {
  margin-top: 1rem;
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
}

/* Ensure no margin or padding on the footer itself */
footer {
  margin: 0;
  padding: 0;
}
