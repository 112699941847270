/* Container styling */
#clients {
  padding: 2rem;
  text-align: center;
  background: linear-gradient(135deg, #ffffff);
  color: #000;
}

#clients h2 {
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

/* Logo container */
.client-logos {
  overflow: hidden; /* Hide logos outside the viewport */
  width: 100%; /* Full width */
  position: relative;
}

.client-logos-wrapper {
  display: flex; /* Align logos in a row */
  animation: scroll 20s linear infinite; /* Infinite scrolling animation */
}

/* Style each logo */
.client-logos img {
  flex: 0 0 auto; /* Prevent shrinking/growing */
  max-width: 200px; /* Adjust as per design */
  height: auto;
  margin: 0 1rem; /* Add spacing between logos */
  filter: grayscale(80%);
  transition: transform 0.3s ease;
}

.client-logos img:hover {
  transform: scale(1.1);
  filter: grayscale(0%);
}

/* Scroll animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move by half the content width */
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .client-logos img {
    max-width: 150px;
  }
}

@media (max-width: 480px) {
  .client-logos img {
    max-width: 100px;
  }
}
